import { useEffect, useState } from "react";
import { getCurrentAccount } from "../network/ethereum";

export const useWallet = () => {
  const { ethereum } = window;
  const [currentAccount, setCurrentAccount] = useState<string | undefined>();

  useEffect(() => {
    const setCurrentWallet = async () => {
      setCurrentAccount(await getCurrentAccount());
    };
    setCurrentWallet();
  }, []);

  // @ts-ignore
  ethereum?.on("accountsChanged", ([newAccount]) => {
    console.log("accountsChanged: ", newAccount);
    setCurrentAccount(newAccount);
  });

  return { currentAccount, setCurrentAccount };
};
