import React from 'react';
import { Button, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";
import { style } from './Header.styles';

type Props = {
    currentAccount?: string,
    setCurrentAccount: React.Dispatch<React.SetStateAction<string | undefined>>
}

const Header: React.FC<Props> = ({ currentAccount, setCurrentAccount }) => {
    const { ethereum } = window;
    ethereum?.request({ method: 'eth_accounts' }).then((value: string[]) => {
        if (value.length > 0) {
            currentAccount = value[0];
        }
    });

    const connectWallet = async () => {
        if (!ethereum) {
            console.log("No wallet plugin is available!");
            return;
        }

        try {
            const [account] = await ethereum.request({ method: 'eth_requestAccounts' });
            setCurrentAccount(account);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <header className="header">
            {style}
            <div className="container">
                <nav className="navbar navbar-expand-xl">
                    <a className="navbar-brand" href="/"><img className='logo' src="images/logo/logo.png" alt="ACC"></img></a>
                    <button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler--icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-end" id="navbarNavAltMarkup">
                        <div className="navbar-nav">
                            <a className="nav-link" aria-current="page" href="/#home">Home</a>
                            <a className="nav-link" href="/#collection">NFT</a>
                            <a className="nav-link" href="/#roadmap">Roadmap</a>
                            <a className="nav-link" href="/#mint">Mint</a>
                            <a className="nav-link" href="/#team">Team</a>
                            <a className="nav-link" href="/#faq">FAQ</a>
                            <a className="nav-link" href="/#media">Media</a>
                            {
                                currentAccount &&
                                <Link className="nav-link" to="/my-token">
                                    My NFTs
                                </Link>
                            }
                        </div>
                        <Nav>
                            {!currentAccount &&
                                <Button disabled={!ethereum} className="social-btns default-btn" variant="flat" onClick={() => connectWallet()}>
                                    Connect to wallet
                                </Button>
                            }
                            {currentAccount &&
                                <Navbar.Text className='address'>
                                    Address: {currentAccount.slice(0, 5)}...{currentAccount.slice(currentAccount.length - 5)}
                                </Navbar.Text>
                            }
                        </Nav>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header;