/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Row } from "react-bootstrap";
import { buyNFT, getMaxSupply, getTotalSupply } from "../../network/ethereum";
import { style } from "./Home.styles";

type Props = {
    currentAccount?: string;
}

const Home: React.FC<Props> = ({ currentAccount }) => {
    const [tokenLeft, setTokenLeft] = useState<number | null>(null);
    const [error, setError] = useState(false);
    const mintDate = new Date('December 01, 2022 12:00:00').getTime();
    const isMintEnabled = new Date().getTime() >= mintDate;

    useEffect(() => {
        const fetchSupply = async () => {
            const totalSupply = await getTotalSupply();
            const maxSupply = await getMaxSupply();
            setTokenLeft(Number(maxSupply) - Number(totalSupply));
        }
        fetchSupply();
    }, []);

    /**
     * If you want to run an effect and clean it up only once (on mount and unmount), 
     * you can pass an empty array ([]) as a second argument.
     * This tells React that your effect doesn’t depend on any values from props or state, so it never needs to re-run.
     */
    useEffect(() => {
        addScript("js/aos.js", () => {
            // addScript("js/custom.js", null);
        });
    }, []);

    const addScript = (src: string, onload: any) => {
        const script = document.createElement("script");
        script.src = src;
        script.async = true;
        script.onload = onload;
        document.body.appendChild(script);
    }

    const onBuyClick = async (e: React.MouseEvent<HTMLElement>) => {
        setError(false);
        if (!currentAccount) {
            alert("You haven't connected your wallet yet!")
        } else {
            try {
                await buyNFT(1);
            } catch (err) {
                setError(true);
            }
        }
    }

    return (
        <div>
            {style}
            <section id="home" className="banner">
                <div className="container">
                    <div className="banner__wrapper">
                        <div className="row g-5 align-items-center justify-content-center">
                            <div className="col">
                                <div className="banner__content text-center" data-aos="fade-up" data-aos-duration="2000">
                                    <h1 className="text--3d text-uppercase"> <span className="color--gradient-y">Alpha</span> <br />
                                        Cheetah Club</h1>
                                    <p className='text--3d2'>7100 Unique Alpha Cheetahs,
                                        Starting Minting The 1st of December</p>

                                    <ul className="social justify-content-center mb-5">
                                        <li className="social__item">
                                            <a href="https://discord.gg/bYBma23cWV" className="social__link">
                                                <img src="images/social/discord.webp" alt="Discord" />
                                            </a>
                                        </li>
                                        <li className="social__item">
                                            <a href="https://twitter.com/acheetahclub" className="social__link">
                                                <img src="images/social/twitter.webp" alt="Twitter" />
                                            </a>
                                        </li>
                                        <li className="social__item">
                                            <a href="https://www.instagram.com/alphacheetahclub" className="social__link">
                                                <img src="images/social/instagram.webp" alt="Instagram" />
                                            </a>
                                        </li>
                                    </ul>
                                    <a href="https://opensea.io/collection/alphacheetahclub" className="default-btn">View on opensea</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ul className="banner__cheetahs">
                    <li className="banner__cheetahs-item aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
                        <img src="images/banner/cheetahs/01.webp" alt="Cheetah 01" />
                    </li>
                    <li className="banner__cheetahs-item aos-init aos-animate" data-aos="fade-right" data-aos-duration="1000">
                        <img src="images/banner/cheetahs/02.webp" alt="Cheetah 02" />
                    </li>
                    <li className="banner__cheetahs-item aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
                        <img src="images/banner/cheetahs/03.webp" alt="Cheetah 03" />
                    </li>
                    <li className="banner__cheetahs-item aos-init aos-animate" data-aos="fade-left" data-aos-duration="1000">
                        <img src="images/banner/cheetahs/04.webp" alt="Cheetah 04" />
                    </li>
                </ul>
            </section>

            <section className="collection padding-top padding-bottom" id="collection">
                <div className="container">
                    <div className="section-header aos-animate aos-init" data-aos="fade-up" data-aos-duration="1000">
                        <div className="section-header__content">
                            <h2> <span className="color--gradient-y">The</span> Collection</h2>
                            <p>7,100 Unique digital cheetahs ready to run in the Ethereum savannah.</p>
                        </div>
                    </div>
                    <div className="collection__wrapper">
                        <div className="collection__thumb">
                            <div className="swiper collection__thumb-slider-1 mb-3">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <div className="collection__thumb-item">
                                            <img loading='lazy' src="images/collection/1.webp" alt="NFT 01" />
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="collection__thumb-item">
                                            <img loading='lazy' src="images/collection/2.webp" alt="NFT 02" />
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="collection__thumb-item">
                                            <img loading='lazy' src="images/collection/3.webp" alt="NFT 03" />
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="collection__thumb-item">
                                            <img loading='lazy' src="images/collection/4.webp" alt="NFT 04" />
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="collection__thumb-item">
                                            <img loading='lazy' src="images/collection/5.webp" alt="NFT 05" />
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="collection__thumb-item">
                                            <img loading='lazy' src="images/collection/6.webp" alt="NFT 06" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper collection__thumb-slider-2">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <div className="collection__thumb-item">
                                            <img loading='lazy' src="images/collection/7.webp" alt="NFT 07" />
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="collection__thumb-item">
                                            <img loading='lazy' src="images/collection/8.webp" alt="NFT 08" />
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="collection__thumb-item">
                                            <img loading='lazy' src="images/collection/9.webp" alt="NFT 09" />
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="collection__thumb-item">
                                            <img loading='lazy' src="images/collection/10.webp" alt="NFT 10" />
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="collection__thumb-item">
                                            <img loading='lazy' src="images/collection/11.webp" alt="NFT 11" />
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div className="collection__thumb-item">
                                            <img loading='lazy' src="images/collection/12.webp" alt="NFT 12" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="text-center mt-5">
                                <a href="https://opensea.io/collection/alphacheetahclub" className="default-btn">View on Opensea</a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section id="mint" className="mint padding-top padding-bottom bg--cover">
                <div className="container">
                    <div className="mint__wrapper">
                        <div className="row g-5 align-items-end">
                            <div className="col-lg-6">
                                <div className="mint__content aos-animate aos-init" data-aos="fade-right" data-aos-duration="1000">
                                    <div className="row gy-5 gx-4 align-items-center">
                                        <div className="col-6">
                                            <div className="mint__item">
                                                <div className="mint__inner">
                                                    <h6 className="mint__sub-title">Project Size</h6>
                                                    <h2 className="mint__numbers">7,100</h2>
                                                    <h4 className="mint__name text-uppercase">NFT's</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="mint__item">
                                                <div className="mint__inner">
                                                    <h6 className="mint__sub-title">Mint Price</h6>
                                                    <h2 className="mint__numbers">0.05</h2>
                                                    <h4 className="mint__name text-uppercase">Ethereum</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="mint__item">
                                                <div className="mint__inner">
                                                    <h6 className="mint__sub-title">Mint Date</h6>
                                                    <h2 className="mint__numbers">1st</h2>
                                                    <h4 className="mint__name text-uppercase">December</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            {
                                                !isMintEnabled &&
                                                <div id="countdown-container" className='countdown-container'>
                                                    <ul id="countdown" className="countdown justify-content-center text--3d2">
                                                        <li className="countdown__item">
                                                            <h3 className="countdown__number color--theme-color countdown__number-days">99</h3>
                                                            <p className="countdown__text">Days</p>
                                                        </li>
                                                        <li className="countdown__item">
                                                            <h3 className="countdown__number color--theme-color countdown__number-hours">18</h3>
                                                            <p className="countdown__text">Hours</p>
                                                        </li>
                                                        <li className="countdown__item">
                                                            <h3 className="countdown__number color--theme-color countdown__number-minutes">44</h3>
                                                            <p className="countdown__text">Minutes</p>
                                                        </li>
                                                        <li className="countdown__item">
                                                            <h3 className="countdown__number color--theme-color countdown__number-seconds">36</h3>
                                                            <p className="countdown__text">Seconds</p>
                                                        </li>
                                                    </ul>
                                                </div>
                                            }

                                            <div className="text-start">
                                                <Row>
                                                    {isMintEnabled &&
                                                        <Col className="d-flex tokens-left">
                                                            {tokenLeft && tokenLeft === 0 &&
                                                                <span>Sold out!</span>
                                                            }
                                                            {tokenLeft && tokenLeft > 0 &&
                                                                <span>{tokenLeft} token{tokenLeft > 1 && 's'} left!</span>
                                                            }
                                                        </Col>
                                                    }
                                                </Row>
                                                <Button id="mintBtn" className="default-btn" variant="flat" onClick={onBuyClick} disabled={!isMintEnabled}>
                                                    Mint Now
                                                </Button>
                                                <Row>
                                                    {error &&
                                                        <Col className="mt-3 d-flex"
                                                            lg={12}>
                                                            <Alert className='transaction-error' variant="danger">
                                                                Transaction failed!
                                                            </Alert>
                                                        </Col>
                                                    }
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="mint__thumb aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                                    <img loading='lazy' src="images/mint/01.webp" alt="Minting" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="roadmap padding-top padding-bottom" id="roadmap">
                <div className="container">
                    <div className="section-header aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                        <div className="section-header__content">
                            <h2> <span className="color--gradient-y"></span> Roadmap</h2>
                            <p>10% of all sales will go directly to a Cheetah Foundation to help to preserve the fastest land animal</p>
                        </div>
                    </div>
                    <div className="roadmap__wrapper">
                        <div className="row g-4">
                            <div className="col-lg-4 col-md-6">
                                <div className="roadmap__item aos-animate aos-init" data-aos="fade-left" data-aos-duration="1000">
                                    <div className="roadmap__inner">
                                        <div className="roadmap__thumb">
                                            <img loading='lazy' src="images/roadmap/01.webp" alt="Roadmap Icon" />
                                        </div>
                                        <div className="roadmap__content">
                                            <h4>Initial launch</h4>
                                            <p>7100 Alpha Cheetahs will be ready to be minted. Stay tuned for the
                                                whitelist!
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="roadmap__item aos-animate aos-init" data-aos="fade-left" data-aos-duration="1000"
                                    data-aos-delay="400">
                                    <div className="roadmap__inner">
                                        <div className="roadmap__thumb">
                                            <img loading='lazy' src="images/roadmap/02.webp" alt="Roadmap Icon" />
                                        </div>
                                        <div className="roadmap__content">
                                            <h4>Free NFT's to holders</h4>
                                            <p>When 25% is sold out, each cheetah holder will receive a free NFT</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="roadmap__item aos-animate aos-init" data-aos="fade-left" data-aos-duration="1000"
                                    data-aos-delay="800">
                                    <div className="roadmap__inner">
                                        <div className="roadmap__thumb">
                                            <img loading='lazy' src="images/roadmap/03.webp" alt="Roadmap Icon" />
                                        </div>
                                        <div className="roadmap__content">
                                            <h4>Giveaways</h4>
                                            <p>When 50% is sold out, 30 ETH will be donated to 60 lucky holders.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="roadmap__item aos-animate aos-init" data-aos="fade-up" data-aos-duration="1000">
                                    <div className="roadmap__inner">
                                        <div className="roadmap__thumb">
                                            <img loading='lazy' src="images/roadmap/04.webp" alt="Roadmap Icon" />
                                        </div>
                                        <div className="roadmap__content">
                                            <h4>Access to merchandise</h4>
                                            <p>When 75% is sold out. Holders get a FREE hat, T-shirt, or hoodie with their Alpha Cheetah printed on the back.
                                                Merch store will be available.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="roadmap__item aos-animate aos-init" data-aos="fade-up" data-aos-duration="1000"
                                    data-aos-delay="400">
                                    <div className="roadmap__inner">
                                        <div className="roadmap__thumb">
                                            <img loading='lazy' src="images/roadmap/05.webp" alt="Roadmap Icon" />
                                        </div>
                                        <div className="roadmap__content">
                                            <h4>Trip to Namibia</h4>
                                            <p>Upon 100% sold out we will give 20 lucky holders a ticket to visit the Cheetah sanctuary in Namibia for 2 people all paid,
                                                for 1 week.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="roadmap__item aos-animate aos-init" data-aos="fade-up" data-aos-duration="1000"
                                    data-aos-delay="800">
                                    <div className="roadmap__inner">
                                        <div className="roadmap__thumb">
                                            <img loading='lazy' src="images/roadmap/06.webp" alt="Roadmap Icon" />
                                        </div>
                                        <div className="roadmap__content">
                                            <h4>Amsterdam Event</h4>
                                            <p>We will organize an event for all token holders in the beautiful city of Amsterdam!
                                                Details to be announced after sold out.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="team padding-top padding-bottom bg--fixed" id="team">
                <div className="container">
                    <div className="section-header aos-init aos-animate" data-aos="fade-up" data-aos-duration="1000">
                        <div className="section-header__content">
                            <h2 className="text--3d"> <span className="color--gradient-y d-block"> Meet</span> Our Team</h2>
                            <p>Some teams call themselves a family, but we are actually one!</p>
                        </div>
                    </div>
                    <div className="team__wrapper">
                        <div
                            className="row justify-content-center row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-5 g-3">
                            <div className="col">
                                <div className="team__item" data-aos="flip-right" data-aos-duration="1000">
                                    <div className="team__inner">
                                        <div className="team__thumb">
                                            <img loading='lazy' src="images/team/01.webp" alt="Team" />
                                        </div>
                                        <div className="team__content">
                                            <h5><a href="https://www.instagram.com/mishpard/">Michael H.</a></h5>
                                            <p>CEO / Founder</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="team__item" data-aos="flip-right" data-aos-duration="1000">
                                    <div className="team__inner">
                                        <div className="team__thumb">
                                            <img loading='lazy' src="images/team/02.webp" alt="Team" />
                                        </div>
                                        <div className="team__content">
                                            <h5><a href="https://www.instagram.com/la_cretrelle/">Laure D.</a></h5>
                                            <p>Marketing</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="team__item" data-aos="flip-right" data-aos-duration="1000">
                                    <div className="team__inner">
                                        <div className="team__thumb">
                                            <img loading='lazy' src="images/team/03.webp" alt="Team" />
                                        </div>
                                        <div className="team__content">
                                            <h5><a href="#">Tom</a></h5>
                                            <p>Junior Assistance</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="team__item" data-aos="flip-right" data-aos-duration="1000">
                                    <div className="team__inner">
                                        <div className="team__thumb">
                                            <img loading='lazy' src="images/team/04.webp" alt="Team" />
                                        </div>
                                        <div className="team__content">
                                            <h5><a href="#">Eline</a></h5>
                                            <p>Artist</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="faq" className="faq padding-top padding-bottom">
                <div className="container">
                    <div className="faq__wrapper">
                        <div className="row align-items-center g-5">
                            <div className="col-lg-6">
                                <div className="section-header">
                                    <div className="section-header__content text-start">
                                        <h2 className="mx-0"> <span className="color--gradient-y d-block"> F.A.Q</span> Questions
                                        </h2>
                                    </div>
                                </div>
                                <div className="accordion" id="faqAccordion2">
                                    <div className="row g-3">
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-up" data-aos-duration="1000">
                                                <div className="accordion__header" id="faq1">
                                                    <button className="accordion__button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#faqBody1"
                                                        aria-expanded="false" aria-controls="faqBody1">
                                                        When is the official launch? <span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody1" className="accordion-collapse collapse" aria-labelledby="faq1"
                                                    data-bs-parent="#faqAccordion2">
                                                    <div className="accordion__body">
                                                        The 1st of December 2022, minting will only be made from our website on the date and time indicated.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-up" data-aos-duration="1100">
                                                <div className="accordion__header" id="faq2">
                                                    <button className="accordion__button" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#faqBody2" aria-expanded="true"
                                                        aria-controls="faqBody2">
                                                        What will the mint price be? <span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody2" className="accordion-collapse collapse show"
                                                    aria-labelledby="faq2" data-bs-parent="#faqAccordion2">
                                                    <div className="accordion__body">
                                                        Mint price of 0.05 ETH (+ gas fee). There are no price tiers, everybody
                                                        gets a cheetah for the same price.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-up" data-aos-duration="1200">
                                                <div className="accordion__header" id="faq3">
                                                    <button className="accordion__button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#faqBody3"
                                                        aria-expanded="false" aria-controls="faqBody3">
                                                        How do I get whitelisted?<span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody3" className="accordion-collapse collapse" aria-labelledby="faq3"
                                                    data-bs-parent="#faqAccordion2">
                                                    <div className="accordion__body">
                                                        We have 1000 whitelist spots, you will be granted one if you are an active member
                                                        of the community and showed dedication to the project.
                                                        Minting will start first for whitelisted people, then 1 week after, the public minting starts.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-up" data-aos-duration="1300">
                                                <div className="accordion__header" id="faq4">
                                                    <button className="accordion__button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#faqBody4"
                                                        aria-expanded="false" aria-controls="faqBody4">
                                                        Why 7100 NFTs?<span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody4" className="accordion-collapse collapse" aria-labelledby="faq4"
                                                    data-bs-parent="#faqAccordion2">
                                                    <div className="accordion__body">
                                                        There are only approximately 7,100 cheetahs left in the wild, our purpose with this project is to raise awareness
                                                        of the vulnerability of this beautiful animal but also other species.
                                                        We are committed to donating 10% of all our funds to several cheetah foundations like
                                                        <a href='https://cheetah.org/' target="_blank" rel="noreferrer">CCF</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-up" data-aos-duration="1400">
                                                <div className="accordion__header" id="faq5">
                                                    <button className="accordion__button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#faqBody5"
                                                        aria-expanded="false" aria-controls="faqBody5">
                                                        When revealed?<span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody5" className="accordion-collapse collapse" aria-labelledby="faq5"
                                                    data-bs-parent="#faqAccordion2">
                                                    <div className="accordion__body">
                                                        The NFTs will be revealed upon sellout or 1 week after the public minting starts.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-up" data-aos-duration="1500">
                                                <div className="accordion__header" id="faq6">
                                                    <button className="accordion__button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#faqBody6"
                                                        aria-expanded="false" aria-controls="faqBody6">
                                                        How many can I mint?<span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody6" className="accordion-collapse collapse" aria-labelledby="faq6"
                                                    data-bs-parent="#faqAccordion2">
                                                    <div className="accordion__body">
                                                        Limited to 10 NFTs per wallet. You can mint using Metamask wallet.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="accordion__item" data-aos="fade-up" data-aos-duration="1500">
                                                <div className="accordion__header" id="faq7">
                                                    <button className="accordion__button collapsed" type="button"
                                                        data-bs-toggle="collapse" data-bs-target="#faqBody7"
                                                        aria-expanded="false" aria-controls="faqBody">
                                                        Will there be royalties?<span className="plus-icon"></span>
                                                    </button>
                                                </div>
                                                <div id="faqBody7" className="accordion-collapse collapse" aria-labelledby="faq7"
                                                    data-bs-parent="#faqAccordion2">
                                                    <div className="accordion__body">
                                                        Yes, 5% so we can continue to invest into the project in the future.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="faq__thumb text-center aos-init aos-animate" data-aos-duration="2000"
                                    data-aos="zoom-in-right">
                                    <img loading='lazy' src="images/faq/01.webp" alt="FAQ" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="community" className="community padding-top padding-bottom">
                <div className="container">
                    <div className="community__wrapper">
                        <div className="community__content text-center" data-aos="zoom-in" data-aos-duration="1000">
                            <h2 className="text--3d"><span className="color--gradient-y d-block">Join The </span> Alpha Cheetah Club</h2>
                            <p>Join a genuine growing community of cheetahs!</p>
                            <a href="https://discord.gg/bYBma23cWV" className="default-btn"><span>Join Our Discord</span></a>
                        </div>
                    </div>
                </div>
            </section>

            <footer className="footer">
                <div className="footer__wrapper padding-top padding-bottom aos-animate aos-ini" data-aos="fade-up"
                    data-aos-duration="1000">
                    <div className="container">
                        <div id="media" className="footer__content text-center t">
                            <h2 className="color--gradient-y">Media</h2>
                            <div className="row g-4">
                                <div className="col-lg-3 col-md-6">
                                    <a href="https://nftcalendar.io/" rel="noreferrer" target="_blank">
                                        <img loading='lazy' src="images/social/nft-calendar.webp" width="275" height="275" alt="nft_calendar" title="NFT Calendar" />
                                    </a>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <a href="https://raritysniper.com/nft-drops-calendar" rel="noreferrer" target="_blank">
                                        <img loading='lazy' src="images/social/rarity-sniper.webp" width="275" height="400" alt="rarity_sniper" title="Rarity Sniper" />
                                    </a>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <a href="https://www.nftdroplist.co.uk/" rel="noreferrer" target="_blank">
                                        <img loading='lazy' src="images/social/nft-drop-list.webp" alt="nft_drop_list" title="NFt Drop List" />
                                    </a>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <a href="https://non-fungi.com/" rel="noreferrer" target="_blank">
                                        <img loading='lazy' src="images/social/non-fungi.webp" width="275" height="275" alt="non_fungible" title="Non Fungible" />
                                    </a>
                                </div>
                                <div className="col-lg-3 col-md-6 white">
                                    <a href="https://nftevening.com/event/alpha-cheetah-club-drop/" rel="noreferrer" target="_blank">
                                        <img loading='lazy' src="images/social/nft-evening.webp" width="275" height="400" alt="nft_evening" title="NFT Evening" />
                                    </a>
                                </div>
                                <div className="col-lg-3 col-md-6">
                                    <a href="https://www.nftdropscalendar.com/" rel="noreferrer" target="_blank">
                                        <img loading='lazy' src="images/social/nft-drop-calendar.webp" width="275" height="400" alt="nft_drop_calendar" title="NFT Drop Calendar" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="footer__content text-center t">
                            <h2 className="color--gradient-y">Follow Us!</h2>
                            <p>If two heads are better than one, 7100 heads should be way better! Join our community.</p>
                            <ul className="social justify-content-center">
                                <li className="social__item">
                                    <a href="https://discord.gg/bYBma23cWV" className="social__link"><img loading='lazy' src="images/social/discord.webp"
                                        alt="Discord" /></a>
                                </li>
                                <li className="social__item">
                                    <a href="https://twitter.com/acheetahclub" className="social__link"><img loading='lazy' src="images/social/twitter.webp"
                                        alt="Twitter" /></a>
                                </li>
                                <li className="social__item">
                                    <a href="https://www.instagram.com/alphacheetahclub" className="social__link"><img loading='lazy' src="images/social/instagram.webp"
                                        alt="Instagram" /></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="footer__copyright">
                    <div className="container">
                        <div className="text-center py-4">
                            <p className=" mb-0">© 2024 Cheetah Labs | All Rights Reserved
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Home;