export const style =
    <>
        <style>
            {`
                /*.buy-button {
                    background-color:  #ffde17;
                    color: black;
                }
                
                .accordion-button:hover {
                    background-color: #ffde17;
                }
                
                .accordion-button:focus {
                    background-color: #ffde17;
                    box-shadow: 0 0 0 .25rem rgba(255, 222, 23, 0.45);
                }
                
                .accordion-button:not(.collapsed) {
                    background-color: #ffde17;
                    color: black;
                }*/

                #home {
                    background-image: url(images/banner/banner-bg.webp);
                }

                #mint { 
                    background-image: url(images/mint/bg.webp);
                }

                #team {
                    background-image: url(images/team/bg.webp);
                }

                #community {
                    background-image:url(images/community/bg.webp)
                }

                @media (min-width: 1200px)
                .h1, h1 {
                    font-size: 6.25rem !important;
                }

                .swiper-slide img {
                    max-height: 200px; // text this on big and small screens
                    /*max-width: 240px;*/
                }

                .countdown__text {
                    font-size: 1rem !important;
                }

                .countdown__number {
                    font-size: 2rem !important;
                }

                .countdown-container {
                    display: grid;
                    grid-template-columns: auto auto auto auto;
                }

                .countdown {
                    gap: 15px;
                }

                @media (min-width: 1200px)
                .countdown {
                    gap: 20px !important;
                }

                @media (min-width: 992px)
                .countdown {
                    gap: 15px !important;
                }

                .text--3d2 {
                    filter: none;
                    -webkit-text-stroke-width: 1px;
                    -webkit-text-stroke-color: #0d142f;
                }

                button:disabled {
                    cursor: not-allowed;
                }

                .tokens-left {
                    margin: 10px 0 0 5px;
                }

                .transaction-error {
                    padding: 5px;
                    font-size: 10px;
                }

                #faq a {
                    color: #0d6efd;
                    text-decoration: underline;
                    cursor: pointer;
                    margin-left: 5px;
                }

                #media {
                    margin-bottom: 100px;
                }

                #media h2 {
                    margin-bottom: 30px;
                }

                #media div.col-lg-3, #media div.col-md-6 {
                    display: flex;
                    justify-content: center;
                }

                #media a {
                    display: flex;
                }

                #media img {
                    align-self: center;
                }

                .white {
                    background-color: white;
                }
            `}
        </style>
    </>