export const style =
    <>
        <style>
            {`
                .navbar-text {
                    color: white;
                    margin-left: 20px;
                }
                
                .btn-rounded {
                    border-radius: 100px;
                }
                
                .connect-button {
                    background-color: white;
                    color: black;
                }

                .logo { 
                    width: 209px;
                }

                .nav-link {
                    color: #fff;
                    font-family: "Alfa Slab One", cursive;
                }

                .nav-link--active, .nav-link:hover, .nav-link:focus {
                    color: #abff87;
                }

                .address {
                    color: #8ca1fb;
                    cursor: pointer;
                    text-decoration: underline;
                }

                .address:hover {
                    color: white;
                }
            `}
        </style>
    </>